import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import axios from 'axios';
import config from './YPCconfig.js';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { GridOverlay, DataGrid } from '@mui/x-data-grid';
import { GridOverlay, DataGrid,  GridToolbarContainer,
  GridToolbarExport,
  gridClasses } from '@mui/x-data-grid';

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ShareIcon from '@material-ui/icons/Share';
import AndroidIcon from '@material-ui/icons/Android';
import PersonIcon from '@material-ui/icons/Person';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Container} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  validate,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";

import MuiAlert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';

import YPCGetMenuDB from './YPCGetMenuDB';
import YPCPutAxios from './YPCPutAxios';
import YPCPutAxios2 from './YPCPutAxios2';
import YPCPutAxios2Alt from './YPCPutAxios2Alt';
import YPCGetAxios from './YPCGetAxios';
import YPCGetAxiosAlt from './YPCGetAxiosAlt';
import YPCDeleteAxios from './YPCDeleteAxios';
import YPCDeleteAxiosAlt from './YPCDeleteAxiosAlt';

import XTranslate from "./YPCXTranslate";
import translate from "./YPCTranslate";
import { duration } from 'moment';
import { set } from 'date-fns';
import { id, te } from 'date-fns/locale';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#78cbff',
    },      
    secondary: {
      main: '#78cbff'
    }
  }
});


const useStyles = makeStyles((theme) => ({
  container: {
      maxWidth: "75%",
      marginTop: "2vh",
      marginBottom: "10vh",
      borderRadius: '0px',
      // backgroundColor: theme.palette.action.disabledBackground,
      backgroundColor: 'white',
      height: 'auto'// 1600,
  },
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2), paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
  },
  sliders: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
  },
  slidertop: {
      marginTop: theme.spacing(4),
  },
  paper: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  form: {
      width: '90%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  submit: {
      // margin: theme.spacing(3, 0, 2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.7),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      display: 'flex',
      width: 151,
      // width: 'auto',
      // height: '100%'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    row: {
      height: '200px',
    },
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'large',// theme.typography.pxToRem(20),
      // border: '5px solid #ffffff',
    },
    image: {
      // border: '5px solid #ffffff',
      float: 'left',
      // width: "200px",
      // height: "auto",
      width: "auto",
      height: "100px",
      marginLeft: '20px',
      marginTop: '20px',
    },
    sidebyside: {
      float: 'left',
      // marginLeft: '20px'
    },
    Textgreen: {
      color: "#33cc33",
      width: "1%",
      whiteSpace: "nowrap"
    },
    Textred: {
          color: "#e60000",
          width: "1%",
          whiteSpace: "nowrap"
        },
    sendleft: {
      float: "left",
      // margineBottom: '10px'
    },
    gridcell:{
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
      '& .super-app.hide': {
        // visibility: 'collapse',
      },
      '& .super-app.show': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
      },
      '& .super-app.grey': {
        backgroundColor: 'rgba(200, 200, 200, 0.49)',
      },
      '& .super-app.indent': {
        // marginLeft: "30px",
        paddingLeft: "30px",
      },
      '& .super-app.noindent': {
      },

    },
    gridoverlay: {
      flexDirection: 'column'
    },
    label: {
      marginTop: theme.spacing(1),
    },

}));


const stateList = ['solid', 'powder', 'liquid', 'paste', 'foam', ''];
const storageTempList = ['room', 'fridge', 'frozen', 'fridge/room', 'room/fridge', 'frozen/fridge'];
const categoryList = ['other', 'meats', 'seafood', 'fruits', 'veggies', 'grains', 'spices', 'dairy', 'nuts', 'legumes', 'standard', 'alcohol', 'sides', 'fungi'].sort();

export default function YPCCreateIngredient(props) {

  const classes = useStyles();
  // const theme = useTheme();
  const [menuLoaded, setMenuLoaded] = React.useState(false);
  
  const [menuDB, setMenuDB] = React.useState([]);
  const [xget, setXGet] = React.useState(null);
  const [xpost, setXPost] = React.useState(null);


  const [ingredientName, setIngredientName] = React.useState("");
  const [state, setState ] = React.useState('solid');
  const [category, setCategory] = React.useState('other');
  const [storageTemp, setStorageTemp ] = React.useState('fridge/room');
  const [caloriesOld, setCaloriesOld ] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState("");
  const [productCode, setProductCode ] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [minFormat, setMinFormat ] = React.useState(1);
  const [unitSize, setUnitSize ] = React.useState(1.0);
  const [unitPrice, setUnitPrice ] = React.useState(0.0);
  const [stock, setStock] = React.useState(0);
  const [density, setDensity] = React.useState(0.0);
  const [retailPrice, setRetailPrice] = React.useState(0.0);

  // Nutrition facts
  const [servingSize, setServingSize] = React.useState("");
  const [calories, setCalories ] = React.useState("");
  const [fat, setFat] = React.useState("");
  const [saturatedFat, setSaturatedFat] = React.useState("");
  const [transFat, setTransFat] = React.useState("");
  const [carbohydrates, setCarbohydrates] = React.useState("");
  const [fibre, setFibre] = React.useState("");
  const [sugars, setSugars] = React.useState("");
  const [protein, setProtein] = React.useState("");
  const [cholesterol, setCholesterol] = React.useState("");
  const [sodium, setSodium] = React.useState("");
  const [potassium, setPotassium] = React.useState("");
  const [calcium, setCalcium] = React.useState("");
  const [iron, setIron] = React.useState("");
  const [dataSource, setDataSource] = React.useState("");

  const [selectedEditIngredient, setSelectedEditIngredient] = React.useState("");
  const [doubleCheckOpen, setDoubleCheckOpen] = React.useState(false);
  const [deleteCheckOpen, setDeleteCheckOpen] = React.useState(false);

  const [ingredientList, setIngredientList] = React.useState([]);
  const [ingredientId, setIngredientId] = React.useState(0);

  const [ingredientTableList, setIngredientTableList] = React.useState([]);


  const [stringed, setStringed] = React.useState(null);

  React.useEffect(() => {
    if (!menuLoaded) {
      handleLoadDB();
    }
  }, [menuLoaded]);



//   React.useEffect(() => {
//     calculateStats();
//   }, [ingredientName, category, state, storageTemp, calories, supplier, productCode, productName, minFormat, unitSize, unitPrice, stock, density, retailPrice , props.language]);


  // React.useEffect(() => {
  //   console.log("PPPPPPPPPPPPPPPPPP", props.universeMaster);
  //   getMenu();
  // }, [selectedDate]);


    React.useEffect(() => {
    processIngredientTableList();
  }, [ingredientList]);

  const handleClearAll = () => {
    setIngredientId(0);
    setIngredientName('');
    setState('solid');
    setCategory('other');
    setStorageTemp('fridge/room');
    setCaloriesOld(0)
    setSupplierId("")
    setProductCode("");
    setProductName("");
    setMinFormat("1");
    setUnitSize(1.0);
    setUnitPrice(0.0);
    setStock(0);
    setDensity(0.0);
    setRetailPrice(0.0);

    // Nutrition facts
    setServingSize("");
    setCalories("")
    setFat("");
    setSaturatedFat("");
    setTransFat("");
    setCarbohydrates("");
    setFibre("");
    setSugars("");
    setProtein("");
    setCholesterol("");
    setSodium("");
    setPotassium("");
    setCalcium("");
    setIron("");
    setDataSource("");

  };

  const handleLoadDB = () => {
    // YPCGetMenuDB(xget, setXGet).then(
    //   function(value) { setMenuDB(value)
    //   }
    // );
    setMenuDB([]);
    setMenuLoaded(true);

    getIngredients();

  };


  
  async function getIngredients() {
    // var result = await YPCGetAxios(xget, setXGet, 'allIngredientsURL', '');
    var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxyallIngredientsURL', '');

    let iList = result;

    iList = iList.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    setIngredientList(iList);

    // console.log('IIIIIIIIIIIIII', result);
  }




  const preDeleteIngredient = () => {
    if (ingredientList.includes(selectedEditIngredient)) {      
      setDeleteCheckOpen(true);
    };
  };

  const preSaveIngredient = () => {
    let preIngredientName = ingredientName.trim()
    // if (ingredientList.includes(ingredientName)) {      
    if (ingredientList.includes(preIngredientName)) {      
      setDoubleCheckOpen(true);
    } else {
      handleSaveIngredient();
    }
  };

  const adaptEmptyStringToNull = (value) => {
    return value === "" ? null:value;
  }

  const adaptNullToEmptyString = (value) => {
      return value === null ? "":value;
  }

  async function handleSaveIngredient() {

    let preIngredientName = ingredientName.trim()

    let globalIngredient = {

      // name: ingredientName,
      name: preIngredientName,
      state: state,
      category: category,
      storage_temp: storageTemp,
      calories: caloriesOld,
      supplier_id: supplierId,
      product_code: productCode,
      product_name: productName,
      min_format: minFormat,
      unit_size: unitSize,
      unit_price: unitPrice,
      stock: stock,
      density: density,
      retail_price: retailPrice,
      id: ingredientId,
      nutrition_records: [],
    };

    if (servingSize !== null && servingSize !== "" && servingSize > 0
      && !(calories === ""
        && fat === ""
        && saturatedFat === ""
        && transFat === ""
        && carbohydrates === ""
        && fibre === ""
        && sugars === ""
        && protein === ""
        && cholesterol === ""
        && sodium === ""
        && potassium === ""
        && calcium === ""
        && iron === ""
        && dataSource === ""
      )
    ) {
      globalIngredient.nutrition_records.push(
        {
          serving_size: adaptEmptyStringToNull(servingSize),
          calories: adaptEmptyStringToNull(calories),
          fat: adaptEmptyStringToNull(fat),
          saturated_fat: adaptEmptyStringToNull(saturatedFat),
          trans_fat: adaptEmptyStringToNull(transFat),
          carbohydrates: adaptEmptyStringToNull(carbohydrates),
          fibre: adaptEmptyStringToNull(fibre),
          sugars: adaptEmptyStringToNull(sugars),
          protein: adaptEmptyStringToNull(protein),
          cholesterol: adaptEmptyStringToNull(cholesterol),
          sodium: adaptEmptyStringToNull(sodium),
          potassium: adaptEmptyStringToNull(potassium),
          calcium: adaptEmptyStringToNull(calcium),
          iron: adaptEmptyStringToNull(iron),
          data_source: dataSource,
        }
      )
    }

    setStringed(globalIngredient);

    // await YPCPutAxios2(setXPost, 'saveIngredientURL', globalIngredient, '');
    await YPCPutAxios2Alt(setXPost, 'putProxysaveIngredientURL', globalIngredient, '');

    getIngredients();

  };


  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getValueFormater = (params) => { 
          if (params.value === null) {
            return (null);
          } else if (params.value < 0) {
              return (0);
          } else {
            return(Math.round(params.value));
          }
  };




  

        const handleRetailPrice = (event) => {
            event.target.value = parseFloat(event.target.value).toString();
            if (parseFloat(event.target.value) < 1 ) {
            event.target.value = 0;
            };
            setRetailPrice(parseFloat(event.target.value));
        };

        const handleSupplier = (event) => {
            setSupplierId(event.target.value);
        };


        const handleIngredientName = (event) => {
            let preIngredientName = event.target.value.replace('&', 'and');
            // setIngredientName(event.target.value);
            setIngredientName(preIngredientName);
        };

        const handleProductCode = (event) => {
            setProductCode(event.target.value);
        };

        const handleProductName = (event) => {
            setProductName(event.target.value);
        };


        const handleDensity = (event) => {
            event.target.value = parseFloat(event.target.value); // .toString();
            if (parseFloat(event.target.value) < 0 ) {
              event.target.value = 0;
            };
              setDensity(parseFloat(event.target.value));
    
            };

    const handleId = (event) => {
        event.target.value = parseInt(event.target.value); // .toString();
        if (parseInt(event.target.value) < 1 ) {
            event.target.value = 1;
        };
            setIngredientId(parseInt(event.target.value));

        };

    const handleMinFormat = (event) => {
        event.target.value = parseInt(event.target.value); // .toString();
        if (parseInt(event.target.value) < 1 ) {
            event.target.value = 1;
        };
            setMinFormat(parseInt(event.target.value));

        };

    const handleUnitSize = (event) => {
        event.target.value = parseInt(event.target.value); // .toString();
        if (parseInt(event.target.value) < 1 ) {
            event.target.value =1;
        };
            setUnitSize(parseInt(event.target.value));

        };

    const handleStock = (event) => {
        event.target.value = parseInt(event.target.value); // .toString();
        if (parseInt(event.target.value) <= 0.0 ) {
            event.target.value =0.0;
        };
            setStock(parseInt(event.target.value));

        };

    const handleUnitPrice = (event) => {
        event.target.value = parseFloat(event.target.value); // .toString();
        if (parseFloat(event.target.value) <= 0.0 ) {
            event.target.value =0.0;
        };
            setUnitPrice(parseFloat(event.target.value));

        };

  const handleServingSizeChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setServingSize("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setServingSize(parseFloat(event.target.value));
    }
  }

  const handleCalories = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setCalories("");
    } else {
      event.target.value = parseInt(event.target.value); // .toString();
      if (parseInt(event.target.value) < 0) {
        event.target.value = 0;
      };
      setCalories(parseInt(event.target.value));
    }
  };

  const handleFatChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setFat("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setFat(parseFloat(event.target.value));
    }
  }

  const handleSaturatedFatChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setSaturatedFat("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setSaturatedFat(parseFloat(event.target.value));
    }
  }

  const handleTransFatChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setTransFat("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setTransFat(parseFloat(event.target.value));
    }
  }

  const handleCarbohydratesChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setCarbohydrates("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setCarbohydrates(parseFloat(event.target.value));
    }
  }

  const handleFibreChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setFibre("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setFibre(parseFloat(event.target.value));
    }
  }

  const handleSugarsChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setSugars("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setSugars(parseFloat(event.target.value));
    }
  }

  const handleProteinChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setProtein("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setProtein(parseFloat(event.target.value));
    }
  }

  const handleCholesterolChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setCholesterol("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setCholesterol(parseFloat(event.target.value));
    }
  }

  const handleSodiumChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setSodium("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setSodium(parseFloat(event.target.value));
    }
  }

  const handlePotassiumChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setPotassium("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setPotassium(parseFloat(event.target.value));
    }
  }

  const handleCalciumChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setCalcium("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setCalcium(parseFloat(event.target.value));
    }
  }

  const handleIronChange = (event) => {
    if (event.target.value.isNull || event.target.value === "") {
      setIron("");
    } else {
      event.target.value = parseFloat(event.target.value);
      if (parseFloat(event.target.value) <= 0.0 ) {
        event.target.value = 0.0;
      };
      setIron(parseFloat(event.target.value));
    }
  }

  const handleDataSourceChange  = (event) => {
    setDataSource(event.target.value);
  };

  async function handleDeleteIngredient() {

    if (selectedEditIngredient !== '' && selectedEditIngredient !== null) {

      // var result = await YPCDeleteAxios(xget, setXGet, 'deleteIngredientURL', selectedEditIngredient);
      var result = await YPCDeleteAxiosAlt(xget, setXGet, 'delProxydeleteIngredientURL', selectedEditIngredient);
      console.log('PSPSPSPSP', result);
      setSelectedEditIngredient('');
      getIngredients();

    }
  }


  const convertBoolToEng = (value) => {
    if (value === null) {
      return 'No';
    } else if (value === false) {
      return 'No';
    } else if (value === true) {
      return 'Yes';
    }

  };


  async function handleLoadEditIngredient() {

    if (selectedEditIngredient !== '' && selectedEditIngredient !== null) {

      handleClearAll();

      // var result = await YPCGetAxios(xget, setXGet, 'getIngredientURL', selectedEditIngredient);
      var result = await YPCGetAxiosAlt(xget, setXGet, 'getProxygetIngredientURL', selectedEditIngredient);

      setIngredientName(selectedEditIngredient);
      setState(result.state  !== null? result.state: 'solid');
      setCategory(result.category  !== null? result.category: 'other');      
      setStorageTemp(result.storage_temp !== null? result.storage_temp: 'fridge/room');
      setCaloriesOld(result.calories  !== null? result.calories: 0.0);
      setSupplierId(result.supplier_id  !== null? result.supplier_id: "");
      setProductCode(result.product_code);
      setProductName(result.product_name);
      setMinFormat(result.min_format  !== null? result.min_format: 0.0);
      setUnitSize(result.unit_size  !== null? result.unit_size: 1.0);
      setUnitPrice(result.unit_price !== null? result.unit_price: 0.0);
      setStock(result.stock !== null? result.stock: 0.0);
      setDensity(result.density  !== null? result.density: 0.0);
      setRetailPrice(result.retail_price  !== null? result.retail_price: 0.0);
      setIngredientId(result.id);

      if (result.nutrition_records.length > 0) {
        const nutrition_facts_record = result.nutrition_records.at(0);
        setServingSize(nutrition_facts_record.serving_size);
        setCalories(adaptNullToEmptyString(nutrition_facts_record.calories));
        setFat(adaptNullToEmptyString(nutrition_facts_record.fat));
        setSaturatedFat(adaptNullToEmptyString(nutrition_facts_record.saturated_fat));
        setTransFat(adaptNullToEmptyString(nutrition_facts_record.trans_fat));
        setCarbohydrates(adaptNullToEmptyString(nutrition_facts_record.carbohydrates));
        setFibre(adaptNullToEmptyString(nutrition_facts_record.fibre));
        setSugars(adaptNullToEmptyString(nutrition_facts_record.sugars));
        setProtein(adaptNullToEmptyString(nutrition_facts_record.protein));
        setCholesterol(adaptNullToEmptyString(nutrition_facts_record.cholesterol));
        setSodium(adaptNullToEmptyString(nutrition_facts_record.sodium));
        setPotassium(adaptNullToEmptyString(nutrition_facts_record.potassium));
        setCalcium(adaptNullToEmptyString(nutrition_facts_record.calcium));
        setIron(adaptNullToEmptyString(nutrition_facts_record.iron));
        setDataSource(nutrition_facts_record.data_source);
      }

      setSelectedEditIngredient('');

    };

  };

  function deleteCheckSave() {
    return (
      <div>
        <Dialog open={deleteCheckOpen} >
          <DialogTitle>Are you sure you want to delete!?</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCheckCancel} color="primary">
              {translate(props.language, 'Cancel')}
            </Button>
            <Button onClick={handleDeleteCheckDelete} color="primary">
              {translate(props.language, 'Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDeleteCheckCancel = () => {
    setDeleteCheckOpen(false);
  }

  const handleDeleteCheckDelete = () => {
    setDeleteCheckOpen(false);
    handleDeleteIngredient();

  }



  function doubleCheckSave() {
    return (
      <div>
        <Dialog open={doubleCheckOpen} >
          <DialogTitle>Ingredient Name Already in Database!</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDoubleCheckCancel} color="primary">
              {translate(props.language, 'Cancel')}
            </Button>
            <Button onClick={handleDoubleCheckSave} color="primary">
              {translate(props.language, 'Save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDoubleCheckCancel = () => {
    setDoubleCheckOpen(false);
  }

  const handleDoubleCheckSave = () => {
    setDoubleCheckOpen(false);
    handleSaveIngredient();

  }

  const printColumns = [
    {
      field: 'name',
      headerName: 'Ingredient Name',
      width: 300,
      sortable: false,
      editable: false
    }
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function processIngredientTableList() {
    let tempList = []
    ingredientList.map((value, index) => {
      tempList.push({
        'id': index,
        'name': value
      })

    });
    setIngredientTableList(tempList)
  }

  function printTable() {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          className={classes.gridcell}
          // hideFooter={true}
          // hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooterSelectedRowCount={true}
          density="compact"
          rows={ingredientTableList}
          columns={printColumns}
          // onCellEditCommit={handleCellEditCommit}
          // pageSize={5}
          // checkboxSelection
          // disableSelectionOnClick
          disableColumnSelector={true}
          disableColumnFilter={true}
          // onRowClick={handleStepsSelect}
          // headerHeight={0}
          autoHeight={true}
          // selectionModel={selectStep}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay className={classes.gridoverlay}>
                <div className={classes.label}>
                </div>
              </GridOverlay>
            ),
            Toolbar:CustomToolbar
          }}
        />
      </div>
    );
  };  


// gen dialog

  const handleGenDialog = () => {
    setXPost(null);
    setXGet(null);
  };

    function genDialog() {
      // console.log('XPXPXPXPXPXPXPXP', xpost)
      return (
        <div>
          <Dialog open={true} 
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
          >
          {/* <Dialog> */}
            <DialogTitle  id="alert-dialog-title" >Info -</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {xpost}{xget}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleGenDialog} color="primary">
                {translate(props.language, 'Ok')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

      );
    };



    return (

          <React.Fragment>

          <Container className={classes.container}  style={{ marginTop: "2em" }}>
          <MuiThemeProvider theme={theme}>

            <Grid container direction="row" alignItems="stretch" justify="left" spacing={2}>
              <Grid item xs={12}>



                  <div className={classes.sendleft}>

                      {doubleCheckOpen ? doubleCheckSave() : null}
                      {deleteCheckOpen ? deleteCheckSave() : null}
                      {xpost !== null || xget !== null ? genDialog() : null}
                      <Typography component="h4" variant="h4">
                          {translate(props.language, 'Create/Edit Ingredient')}
                      </Typography>
                  </div>


                  <div style={{clear: "both"}}></div>
              </Grid>



                {/* Attributes Section  */}
              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Attributes')} &#8198;</div>
              </Grid>


              <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                    <Grid item xs={3}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={ingredientName}
                                id="outlined-name"
                                label={translate(props.language,'Name')}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleIngredientName}
                                // variant="outlined"
                            />
                    </Grid>
                    <Grid item xs={3}>

                            <Autocomplete
                                onChange={(event, value) => setState(value)}
                                id="combo-box7"
                                value={state}
                                options={stateList}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='State' InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />


                    </Grid>
                    <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event, value) => setCategory(value)}
                                id="combo-box7"
                                value={category}
                                options={categoryList}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='Category' InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />

                    </Grid>
                    <Grid item xs={3}>

                            <Autocomplete
                                onChange={(event, value) => setStorageTemp(value)}
                                id="combo-box7"
                                value={storageTemp}
                                options={storageTempList}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label='Storage Temp' InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />

                    </Grid>


                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                        {/*<Grid item xs={3}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth='true'*/}
                        {/*        color='primary'*/}
                        {/*        value={calories}*/}
                        {/*        id="outlined-name"*/}
                        {/*        label={translate(props.language,'Calories')}*/}
                        {/*        type="number"*/}
                        {/*        InputLabelProps={{*/}
                        {/*            shrink: true,*/}
                        {/*            style: {color: '#78cbff'},*/}
                        {/*        }}*/}
                        {/*        onChange={handleCalories}*/}
                        {/*        // variant="outlined"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={3}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={supplierId}
                                id="outlined-name"
                                label={translate(props.language,'Supplier')}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleSupplier}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={productCode}
                                id="outlined-name"
                                label={translate(props.language,'Product Code')}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleProductCode}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={productName}
                                id="outlined-name"
                                label={translate(props.language,'Product Name')}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleProductName}
                                // variant="outlined"
                            />
                        </Grid>
                        
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={minFormat}
                                id="outlined-name"
                                label={translate(props.language,'Min Format')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleMinFormat}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={unitSize}
                                id="outlined-name"
                                label={translate(props.language,'Unit Size')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleUnitSize}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={unitPrice}
                                id="outlined-name"
                                label={translate(props.language,'Unit Price')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleUnitPrice}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={stock}
                                id="outlined-name"
                                label={translate(props.language,'Stock')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleStock}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={density}
                                id="outlined-name"
                                label={translate(props.language,'Density')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleDensity}
                                // variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth='true'
                                color='primary'
                                value={retailPrice}
                                id="outlined-name"
                                label={translate(props.language,'Retail Price')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                onChange={handleRetailPrice}
                                // variant="outlined"
                            />
                        </Grid>
                        
                </Grid>
              </Grid>

              {/* Nutrition Facts Section  */}
              <Grid item xs={12}>
                <br />
                <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Nutrition Facts')} &#8198;</div>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={servingSize}
                      id="outlined-name"
                      label={translate(props.language,'Serving Size') + " (g/ml)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleServingSizeChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={calories}
                      id="outlined-name"
                      label={"Calories"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleCalories}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={fat}
                      id="outlined-name"
                      label={translate(props.language,'Fat') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleFatChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={saturatedFat}
                      id="outlined-name"
                      label={translate(props.language,'Saturated Fat') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleSaturatedFatChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={transFat}
                      id="outlined-name"
                      label={translate(props.language,'Trans Fat') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleTransFatChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={carbohydrates}
                      id="outlined-name"
                      label={translate(props.language,'Carbohydrates') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleCarbohydratesChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={fibre}
                      id="outlined-name"
                      label={translate(props.language,'Fibre') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleFibreChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={sugars}
                      id="outlined-name"
                      label={translate(props.language,'Sugars') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleSugarsChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={protein}
                      id="outlined-name"
                      label={translate(props.language,'Protein') + " (g)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleProteinChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={cholesterol}
                      id="outlined-name"
                      label={translate(props.language,'Cholesterol') + " (mg)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleCholesterolChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={sodium}
                      id="outlined-name"
                      label={'Sodium (mg)'}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleSodiumChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={potassium}
                      id="outlined-name"
                      label={'Potassium (mg)'}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handlePotassiumChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={calcium}
                      id="outlined-name"
                      label={'Calcium (mg)'}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleCalciumChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={iron}
                      id="outlined-name"
                      label={translate(props.language,'Iron') + " (mg)"}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleIronChange}
                      // variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth='true'
                      color='primary'
                      value={dataSource}
                      id="outlined-name"
                      label={translate(props.language,'Data Source')}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#78cbff'},
                      }}
                      onChange={handleDataSourceChange}
                      // variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>

            <Grid item xs={12} >          

                <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleClearAll}
                        disabled={false}
                        aria-label="whatever 1"
                        style={{backgroundColor: '#78cbff', color: "white"}}
                    >
                        {translate(props.language,'Clear All')}
                    </Button>        

                    <p></p>
                  </Grid>

                  <Grid item xs={3} >
                    <p></p>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        className={classes.button}
                        onClick={preSaveIngredient}
                        disabled={false}
                        aria-label="whatever 1"
                        style={{backgroundColor: '#78cbff', color:"white"}}
                    >
                        {translate(props.language,'Save to DB')}
                    </Button>        

                  </Grid>


                  <Grid item xs={6} ></Grid>
              </Grid>


              {/* Start of Edit Existing Ingredient Section */}
              <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{translate(props.language,'Edit Existing Ingredient')} &#8198;</div>
              </Grid>
              <Grid item xs={12}>
                  {menuDB ?     
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Autocomplete
                                onChange={(event, value) => setSelectedEditIngredient(value)}
                                value = {selectedEditIngredient}
                                id="combo-box11"
                                options={ingredientList}
                                // getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={translate(props.language,'Select')} InputLabelProps={{
                                    shrink: true,
                                    style: {color: '#78cbff'},
                                }}
                                // variant="outlined"
                                />} 
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleLoadEditIngredient}
                                disabled={false}
                                aria-label="select existing ingredient"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Load
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                                <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                // onClick={handleDeleteIngredient}
                                onClick={preDeleteIngredient}
                                disabled={false}
                                aria-label="select existing ingredient"
                                style={{backgroundColor: '#78cbff', color: 'white'}}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item xs={8} >
                            {/* {SidesTable(3)} */}

                        </Grid>
                      </Grid>
                  : null }
              </Grid>
              {/* End Existing Ingredient Section */}


              <div><p></p></div>

              {/* {xget !== null ? 
                xget[0] === 'E' ? <Alert severity="error"><Typography>{xget}</Typography></Alert>: null 
              : null}


              {xpost !== null ? 
                  xpost[0] === 'E' ? <Alert severity="error"><Typography>{xpost}</Typography></Alert>: null 
                : null}

              {xpost !== null ? 
                  xpost[0] === 'O' ? <Alert severity="info"><Typography>{xpost}</Typography></Alert>: null 
                : null} */}

            {stringed !== null ?
                <pre>{JSON.stringify(stringed, null, 2) }</pre> 
              : null
            }

            <Grid item xs={12}>
                            <br />
                            <div style={{backgroundColor: '#78cbff', color: 'white', paddingLeft: '10px', height: '40px', paddingTop: '10px'}}>{'Print All Ingredients'} &#8198;</div>
              </Grid>
                <Grid item xs={12} >
                      {printTable()}
                </Grid>      

                </Grid>

          </MuiThemeProvider>
          </Container>

          </ React.Fragment>



    );



}



